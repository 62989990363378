.container {
  width: 100vw;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-size: 20px;
  }
}
